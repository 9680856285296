import './App.css';
import { TextImage } from './sections/TextImage';
import { Header } from './sections/Header';
import { Text } from './sections/Text';
import { Footer } from './sections/Footer';
import { Phrase } from './sections/Phrase';
import { Contact } from './sections/Contact';
import { WhatsAppIcon } from './components/WhatsappIcon';
import { Review } from './sections/Review';

function App() {
  return (
    <div className="App" style={{backgroundImage: `url('/textura.png')`, backgroundSize: 'cover', backgroundPosition: 'center'}} alt="Foto Principal"> 
      <WhatsAppIcon/>
      <Header/>
      <TextImage 
        title={['Ansiedade, estresse, medos...<br>Se esses sentimentos e emoções estão impedindo você de viver plenamente, é hora de buscar ajuda.']}
        paragraphs={['<b>Estou aqui para te ouvir e te acolher.<br>Escutarei sua história e ajudarei a curar suas dores emocionais.</b><br>O que já aconteceu não pode ser mudado, mas seus sentimentos em relação ao que aconteceu podem mudar.' , 'Agende uma consulta agora mesmo.']} 
        image={'/fabi1.png'}
        altImage={'Foto Principal'}
        imageRightSide={true}
        fontColorTitles={'paleta-cor1'}
        fontColorParagraphs={'paleta-cor1'}
        fontColorSubtitle={'rose'}
        buttonColor={'paleta-cor1'}
      />

      <Phrase 
          titleFont={'montserrat'} 
          title={'- Atendimento para jovens, adultos e idosos -'} 
          paragraphs={['Atendimento Online e Presencial em Criciúma-SC']} 
          background={'paleta-cor4'} 
          buttonColor={'paleta-cor1'}
      />
      
      <Text titleFont={'montserrat'} 
            subtitle={'Abordagem'} 
            title={'Descubra as origens do seu sofrimento e liberte-se!'} 
            paragraphs={
                [
                  'Muitos problemas têm origem em padrões de pensamentos e comportamentos formados na infância. '+
                  'Ignorar esses padrões pode levar ao agravamento dos sintomas, afetando seus relacionamentos, carreira e saúde mental. '+
                  'Em alguns casos, isso pode culminar em ansiedade generalizada, depressão e outras psicopatologias.',
                  'Na terapia, é possível identificar e transformar esses padrões, promovendo alívio duradouro e uma vida mais plena.',
                  '<b>Não espere que seus problemas se agravem.</b>',
                  'Invista em você e no seu bem-estar. <b>Agende uma consulta agora</b> mesmo.'
                ]
              } 
            background={'white'}
            buttonColor={'paleta-cor1'}
            titleColor={'paleta-cor1-color'}
            textColor={'paleta-cor1-color'}
            retreat={true}
      />

      <TextImage  
        title={['Fabiana Manarim Dias','<small>CRP-12/27236</small>']}
        subtitle={'Sobre mim'}
        paragraphs={
          [
            'Sou casada a 25 anos, mãe de dois filhos, protetora dos animais e ajudo pessoas através da psicóloga clínica. ', 
            'Utilizo a <b>Terapia do Esquema</b> para explorar como as experiências anteriores moldam os comportamentos e emoções na vida adulta. A partir desse entendimento, desenvolvo planos que promovem mudanças nos comportamentos indesejados.',
            'Compreender as raízes dos nossos problemas é o primeiro passo para a cura. ', 
            'Meu compromisso é oferecer um espaço seguro e acolhedor, onde você será ouvido(a) e compreendido(a). Estou aqui para ajudar você a se reconectar com sua essência, superar seus medos e construir a vida que deseja.'
          ]
        } 
        image={'/fabi2.png'}
        altImage={'Psicologa Fabiana M. Dias'}
        imageRightSide={false}
        background={'white'}
        fontColorTitles={'paleta-cor1'}
        fontColorSubtitle={'paleta-cor1'}
        fontColorParagraphs={'paleta-cor1'}
        buttonColor={'paleta-cor1'}
        colorHr={'paleta-cor1'}
        fontSize='1.1rem'
        backgroundImage={'background-image-bege2'}
      />


      {/*<Slide/>*/}
      <Review reviews={['"<i>Procurar a Fabiana foi a melhor decisão que tomei para mim mesma. A forma como conduz as sessões é excelente. Ela entende exatamente o que sinto. Descobri traumas de infância que ainda impactavam minha vida adulta, e hoje, com sua ajuda, transformei essas dores em força e autoconfiança."<i><br><br>— C.M. 43 anos —',
                        '"<i>Fabiana me ajudou em um processo de autoconhecimento que eu nem sabia que era possível. Sua compreensão profunda me fez entender as causas de comportamentos que me impediam de ser feliz. Estou muito satisfeita e evoluo a cada sessão."<i><br><br>— R.P. 22 anos —',
                        '"<i>Desde que comecei a terapia com Fabiana, minha forma de lidar com emoções e relacionamentos mudou completamente. Ela é incrível, traz clareza para os problemas e cria um ambiente seguro para explorar minhas dificuldades. O impacto na minha vida tem sido imenso."<i><br><br>— A.R. 76 anos —']}
              fontColorTitles={'paleta-cor1'}
      />
      <Contact 
          title={"O caminho para uma vida com mais clareza começa agora."} 
          sentence={["Estou aqui para te ouvir com empatia e acompanhar seu processo de transformação.", "<b>Entre em contato e comece a percorrer o caminho para uma vida com mais propósito e clareza.</b>"]} 
          phone={"(48) 99168-2656"} 
          mail={"fabi.dias78@gmail.com"} 
          adress={"Criciúma-SC"}
          fontColorTitles={'paleta-cor1'}
          fontColorParagraphs={'paleta-cor1'}
      />
      <Footer/>
      </div>
    );
}

export default App;


